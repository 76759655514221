import axios from "axios";

const PATHNAME = document
  .querySelector("body")
  .addEventListener("click", function (e) {
    return window.location.pathname;
  });

window.addEventListener("click", handleLocationChange);

const DOMAIN = window.location.hostname;

const headers = {
  Accept: "*",
  Accept: "application/json",
  "Content-type": "application/json",
  "X-Client-Domain": DOMAIN,
  "x-url-path": PATHNAME,
};

function handleLocationChange() {
  const path = window.location.pathname;
  headers["x-url-path"] = path;
  // Do whatever you need to do with the path variable
}

const baseUrl = () => {
  return process.env.REACT_APP_BASE_URL_ACADEMY;
};

const instance = axios.create({
  baseURL: baseUrl(),
  headers: headers,
});

//Set AUTH token for any request
instance.interceptors.request.use(function (config) {
  const token =
    typeof window !== "undefined" &&
    localStorage.getItem("userinfo") &&
    localStorage.getItem("userinfo").replace(/['"]+/g, "");

  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

const doAPI = {
  //Get data from the server by method = GET
  getData: (URL, parameters = {}) =>
    instance({
      method: "GET",
      url: URL,
      params: parameters,
    }),
  //Post JSON Object on the server by method = POST
  postData: (URL, parameters = {}) =>
    instance({
      method: "POST",
      url: URL,
      data: parameters,
    }),
  //Post Fromdata on the server by method = POST
  postWithImage: async (url, body, callback) => {
    const token = localStorage.getItem("userinfo")
      ? localStorage.getItem("userinfo")
      : "";
    const formData = createFormData(body);
    return axios
      .post(baseUrl() + "/" + url, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: token ? `Bearer ${token.replace(/['"]+/g, "")}` : "",
        },
        transformRequest: (formData) => formData,
        onUploadProgress: callback,
      })
      .catch((error) => {
        throw error;
      });
  },
  //Post JSON Object on the server by method = PUT
  putData: (URL, parameters = {}) =>
    instance({
      method: "PUT",
      url: URL,
      data: parameters,
    }),
  // Delete data from the server by method = DELETE
  deleteData: (URL) =>
    instance({
      method: "DELETE",
      url: URL,
    }),
};

/**
 * Helper function to create a from data
 * @param {*} body
 * @returns
 */
const createFormData = (body) => {
  const data = new FormData();
  Object.keys(body).forEach((key) => {
    data.append(key, body[key]);
  });
  return data;
};

export default doAPI;
