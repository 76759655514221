import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice } from "./utils";

const CustomInput = ({
  validate,
  defaultValue,
  type,
  className,
  placeholder,
  name,
  value = "", // Default value to an empty string
  onChange,
  onBlur,
  otherLang,
  style,
  checked,
  disabled,
  separator,
  ...props
}) => {
  const { t } = useTranslation();
  const [err, setErr] = useState(false);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleOnChange = event => {
    onChange(event);
  };

  const applyFormatting = event => {
    event.target.value = separator ? formatPrice(parseFloat(event.target.value)) : event.target.value;
  };

  const debouncedApplyFormatting = debounce(applyFormatting, 300);

  const handleOnFocus = event => {
    if (event.target.value.includes(",")) {
      event.target.value = event.target.value.replace(/,/g, "");
    }
  };

  const styles = {
    ...style,
    backgroundSize: 18,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "calc(100% - 3px) center",
    backgroundColor: "#fff",
    backgroundImage:
      !validate && value && value.length > 0
        ? "url(/assets/images/check_icon.png)"
        : validate && validate.length > 0
          ? "url(/assets/images/cross_icon.png)"
          : "none",
  };

  return (
    <div className="customInput">
      <input
        maxLength={props.maxLength}
        type={type}
        className={`${className} ${validate && validate.length > 0 && "errorInput border-danger text-danger"}`}
        placeholder={t(placeholder)}
        name={name}
        disabled={disabled}
        value={value} // Controlled input
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={separator ? debouncedApplyFormatting : onBlur}
        style={styles}
        checked={checked}
      />
      {/* <div className='error'>{err && `Value should be in ${currentLanguage}`}</div> */}
    </div>
  );
};

export default CustomInput;
