import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const images = [
	{
		category: 'Yellow Ball Tournament',
		photos: [
			'./assets/images/Gallery/monster-tennis/monster-tennis-1.jpg',
			'./assets/images/Gallery/monster-tennis/monster-tennis-2.jpg',
			'./assets/images/Gallery/monster-tennis/monster-tennis-3.jpg',
			'./assets/images/Gallery/monster-tennis/monster-tennis-4.jpg',
			'./assets/images/Gallery/monster-tennis/monster-tennis-5.jpg',
		],
	},
	{
		category: 'Princeton and Haverford visits',
		photos: [
			'./assets/images/Gallery/college/college-1.jpg',
			'./assets/images/Gallery/college/college-2.jpg',
			'./assets/images/Gallery/college/college-3.jpg',
			'./assets/images/Gallery/college/college-4.jpg',
			'./assets/images/Gallery/college/college-5.jpg',
			'./assets/images/Gallery/college/college-6.jpg',
			'./assets/images/Gallery/college/college-7.jpg',
			'./assets/images/Gallery/college/college-8.jpg',
		],
	},
	{
		category: 'Showcase Showdown in New Jersey',
		photos: [
			'./assets/images//Gallery/college/jersey-showdown-1.jpg',
			'./assets/images//Gallery/college/jersey-showdown-2.jpg',
			'./assets/images//Gallery/college/jersey-showdown-3.jpg',
			'./assets/images//Gallery/college/jersey-showdown-4.jpg',
			'./assets/images//Gallery/college/jersey-showdown-5.jpg',
			'./assets/images//Gallery/college/jersey-showdown-6.jpg',
			'./assets/images//Gallery/college/jersey-showdown-7.jpg',
			'./assets/images//Gallery/college/jersey-showdown-8.jpg',
			'./assets/images//Gallery/college/jersey-showdown-9.jpg',
			'./assets/images//Gallery/college/jersey-showdown-10.jpg',
		],
	},
	{
		category: 'Red Ball Players',
		photos: [
			'./assets/images/Gallery/monster-tennis/Red-Ball-1.jpeg',
			'./assets/images/Gallery/monster-tennis/Red-Ball-2.jpeg',
			'./assets/images/Gallery/monster-tennis/Red-Ball-3.jpeg',
			'./assets/images/Gallery/monster-tennis/Red-Ball-4.jpeg',
			'./assets/images/Gallery/monster-tennis/Red-Ball-5.jpeg',
			'./assets/images/Gallery/monster-tennis/Red-Ball-6.jpeg',
			'./assets/images/Gallery/monster-tennis/Red-Ball-7.jpeg',
			'./assets/images/Gallery/monster-tennis/Red-Ball-8.jpeg',
			'./assets/images/Gallery/monster-tennis/Red-Ball-9.jpeg',
			'./assets/images/Gallery/monster-tennis/Red-Ball-10.jpeg',
			'./assets/images/Gallery/monster-tennis/Red-Ball-11.jpeg',
			'./assets/images/Gallery/monster-tennis/Red-Ball-12.jpeg',
		],
	},
];

const Gallery = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [photoIndex, setPhotoIndex] = useState(0);
	const [selectedImages, setSelectedImages] = useState([]);
	const [activeCategoryIndex, setActiveCategoryIndex] = useState(1);

	useEffect(() => {
		setSelectedImages(images[0].photos);
	}, []);
	const handleImageClick = (categoryIndex, photoIndex) => {
		setSelectedImages(images[categoryIndex].photos);
		setPhotoIndex(photoIndex);
		setIsOpen(true);
	};

	const handleCategoryClick = (index) => {
		setActiveCategoryIndex(index === activeCategoryIndex ? null : index);
	};

	return (
		<div className="bg-gray-50 py-8" data-aos="fade-up">
			<Container className="mx-auto px-4 py-12">
				<div className="col-md-12 text-center mb-5 heading">
					{/* <h6 className="text-theme">Gallery</h6> */}
					<h2 className="mb-4 headingFont">Explore Our Gallery</h2>
				</div>
				<div className="flex flex-wrap justify-center items-center w-100 mb-4">
					{images.map((category, categoryIndex) => (
						<div key={categoryIndex} className="p-1">
							<Button
								onClick={() => handleCategoryClick(categoryIndex)}
								variant={activeCategoryIndex === categoryIndex ? 'theme' : 'outline-secondary'}>
								{category.category}
							</Button>
						</div>
					))}
				</div>

				{activeCategoryIndex !== null && (
					<div>
						{/* <h4 className="text-center my-4">{images[activeCategoryIndex].category}</h4> */}
						<Row className="g-1">
							{images[activeCategoryIndex].photos.map((photo, photoIndex) => (
								<Col key={photoIndex} className="transition_card" lg={3} md={4}>
									<Card
										className="overflow-hidden relative"
										style={{ height: 200, cursor: 'pointer' }}
										onClick={() => handleImageClick(activeCategoryIndex, photoIndex)}>
										<div className="overlayBg">+</div>
										<img
											src={photo}
											alt={images[activeCategoryIndex]?.category}
											className="w-100 h-100 object-cover"
											// alt={`Gallery image ${photoIndex + 1}`}
										/>
									</Card>
								</Col>
							))}
						</Row>
					</div>
				)}

				{isOpen && (
					<Lightbox
						mainSrc={selectedImages[photoIndex]}
						nextSrc={selectedImages[(photoIndex + 1) % selectedImages.length]}
						prevSrc={selectedImages[(photoIndex + selectedImages.length - 1) % selectedImages.length]}
						onCloseRequest={() => setIsOpen(false)}
						onMovePrevRequest={() => setPhotoIndex((photoIndex + selectedImages.length - 1) % selectedImages.length)}
						onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % selectedImages.length)}
					/>
				)}
			</Container>
		</div>
	);
};

export default Gallery;
