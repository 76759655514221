import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import LtModal from '../component/LtModal';

import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';

const coaches = [
	{
		name: 'Deepak Nair',
		title: 'High Performance Coach',
		image: './assets/images/Coaches/Coach_Deepak_Nair.jpeg',
		description:
			'<p>Coach Deepak brings over a decade of coaching experience to the court, holding certifications as an International Tennis Federation (ITF) Level 1 coach and a United States Professional Tennis Registry (USPTR) Senior Level coach. Specializing in junior development, he is dedicated to teaching fundamental and coordination skills that enhance both athleticism and tennis prowess.</p> <p>Coach Deepak’s passion lies in making tennis lessons engaging and challenging, aiming to foster a love for the sport that lasts a lifetime. He takes pride in witnessing his students master new skills and grow as players.</p> <p>Previously, Coach Deepak mentored Rohan Mehra, who has since advanced to the ATP Tour as a professional tennis player.</p>',
	},
	{
		name: 'Lea Lai',
		title: 'Development Coach',
		image: './assets/images/Coaches/Coach_Lea_Lai.jpeg',
		description:
			"<p>Coach Lea has been a cornerstone of the HK International Tennis Academy since 2009, specializing in mini tennis. With a Coaching Expert Certificate for kids' tennis and extensive training from the Hong Kong Tennis Association, the International Tennis Federation, and the Professional Tennis Registry, she brings a wealth of expertise to her role.</p><p> Over the years, Coach Lea has introduced thousands of young players to the sport, nurturing many from beginners to top players in Hong Kong. In addition to her hands-on coaching, she has played a pivotal role in developing the mini tennis program at HKITA and mentoring fellow mini tennis coaches. Her dedication and innovative approach ensure that every child she teaches receives an exceptional start in tennis.</p>",
	},
	{
		name: 'Satender Gusain',
		title: 'High Performance Coach',
		image: './assets/images/Coaches/Coach_Satender_Gusain.jpeg',
		description:
			'<p>Coach Satender, hailing from India, is a dynamic former professional tennis player with an impressive legacy. As a junior competitor, he faced off against some of India’s top players, including Mahesh Bhupathi and Leander Paes. With 18 years of coaching experience under his belt, Coach Satender has specialized in nurturing young talent, guiding several juniors from their first swings to national-level success.</p><p> His extensive experience and deep understanding of the game make his training sessions both exhilarating and highly effective. Coach Satender’s commitment to developing future champions and his passion for the sport ensure that every player he trains gets the best start possible in their tennis journey.</p>',
	},
];

const CoachList = () => {
	const sliderRef = useRef(null);
	const [show, setShow] = useState(false); // State for modal visibility
	const [selectedCoach, setSelectedCoach] = useState(null); // State for selected coach

	const handleClose = () => setShow(false);
	const handleShow = (coach) => {
		setSelectedCoach(coach);
		setShow(true);
	};

	const settings = {
		dots: false,
		arrows: false,
		infinite: false,
		// centered: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div className="bg-gray-50 py-8 overflow-hidden">
			<div className="container mx-auto px-4 py-12">
				<div className="col-md-12 text-center mb-5 heading pr-5 md:pr-3">
					<h6 className="text-theme">Highlight</h6>
					<h2 className="mb-4 font-bold headingFont">Our Coaches</h2>
					{/* <p className="mb-0 text-center">
						We are happy to announce a new addition to our programme offer, which is an annual programme addressed to younger players, starting the
						week of September 9th.
					</p>
					<p className="mb-0 text-center">
						Education has always been one of our priorities. Our junior development programmes are created in such a way that allows players to both
						train and study.
					</p> */}
				</div>
				<Slider ref={sliderRef} {...settings}>
					{coaches.map((coach, index) => (
						<div key={index} className="flex flex-col items-center justify-center px-2">
							<div className="program_card" onClick={() => handleShow(coach)}>
								<img
									src={coach.image}
									alt={coach.name}
									className="aspect-square h-[300px] w-full max-w-[300px] rounded object-cover shadow-sm"
								/>
								<div className="sectionInfo p-4">
									<h5 className="text-xl font-bold mb-2">{coach.name}</h5>
									<p className="text-sm text-white">{coach.title}</p>
								</div>
							</div>
							<div className="flex flex-col p-4 text-center lg:hidden">
								<h5 className="mb-2 text-xl font-bold">{coach.name}</h5>
								<p className="text-sm">{coach.title}</p>
							</div>
						</div>
					))}
				</Slider>

				<div className="mt-4 text-center">
					<button
						onClick={() => sliderRef.current.slickPrev()}
						className="inline-block h-[50px] w-[50px] bg-black hover:scale-110 border-none transition-all hover:bg-slate-800 rounded-full m-2 pb-1">
						<MdOutlineArrowBackIosNew className="text-white" />
					</button>
					<button
						onClick={() => sliderRef.current.slickNext()}
						className="inline-block h-[50px] w-[50px] bg-black hover:scale-110 border-none transition-all hover:bg-slate-800 rounded-full m-2 pb-1">
						<MdOutlineArrowForwardIos className="text-white" />
					</button>
				</div>

				{/* Bootstrap Modal */}
				<LtModal isOpen={show} onClose={handleClose} heading="" width={500}>
					<div className="text-center mx-auto">
						<img src={selectedCoach?.image} alt={selectedCoach?.name} className="mb-3 h-[200px] w-[200px] object-cover rounded-lg mx-auto" />
					</div>
					<h4 className="mt-3 text-center fw-bold">{selectedCoach?.title}</h4>
					<p className="text-center">{selectedCoach?.country}</p>
					<p className="text-justify" dangerouslySetInnerHTML={{ __html: selectedCoach?.description }} />
				</LtModal>
			</div>
		</div>
	);
};

export default CoachList;
