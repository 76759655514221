// src/pages/Home.js
import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import ImageSlider from './ImageSlider';
import ProgramSection from './ProgramSection';
import Testimonials from './Testimonials';
import CoachList from './CoachList';
import PlayerList from './PlayerList';
import Gallery from './Gallery';
import MonsterTennis from './MonsterTennis';
import OurStorySection from './OurStorySection';

const Home = () => {
	React.useEffect(() => {
		AOS.init();
	}, []);

	return (
		<>
			<ImageSlider />
			<ProgramSection />
			<PlayerList />
			<Gallery />
			<CoachList />
			<Testimonials />
			<MonsterTennis />
			<OurStorySection />
		</>
	);
};

export default Home;
