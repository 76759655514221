import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { HiOutlineMenuAlt3, HiOutlineX } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';

export default function Header() {
	const [isOpen, setIsOpen] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const location = useLocation();

	useEffect(() => {
		if (location.pathname === '/') {
			const handleScroll = () => {
				if (window.scrollY > 200) {
					setIsScrolled(true);
				} else {
					setIsScrolled(false);
				}
			};
			window.addEventListener('scroll', handleScroll);
			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		} else {
			setIsScrolled(true);
		}
	}, []);

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<Navbar className={`${isScrolled ? 'scrolled custom' : ''}`} fixed="top" data-bs-theme={isScrolled ? 'light' : 'dark'}>
				<Container>
					<Navbar.Brand href="/#home">
						<img alt="logo" src="./assets/images/hkita_logo.svg" />
					</Navbar.Brand>
					<div className="text-white h1 mb-0" onClick={toggleSidebar} style={{ cursor: 'pointer' }}>
						<HiOutlineMenuAlt3 />
					</div>
				</Container>
			</Navbar>

			{/* Custom Sidebar */}
			<div className={`custom-sidebar ${isOpen ? 'open' : ''}`}>
				<button className="close-btn h1" onClick={toggleSidebar}>
					<HiOutlineX />
				</button>
				<Nav className="flex-column max-h-[100vh] flex-nowrap items-center justify-center overflow-auto headingFont">
					<Nav.Link href="/#home" onClick={toggleSidebar}>
						Home
					</Nav.Link>
					<Nav.Link href="/monster-tennis" onClick={toggleSidebar}>
						Monster Tennis
					</Nav.Link>
					<Nav.Link href="/our-story" onClick={toggleSidebar}>
						Our Story
					</Nav.Link>
					<Nav.Link href="/court-of-opportunities" onClick={toggleSidebar}>
						Court Of Opportunities
					</Nav.Link>
					{/* <Nav.Link href="/#programs" onClick={toggleSidebar}>
						Programs
					</Nav.Link> */}
					<Nav.Link href="/#players" onClick={toggleSidebar}>
						Players
					</Nav.Link>
					<Nav.Link href="/register" onClick={toggleSidebar}>
						Register
					</Nav.Link>
					{/* <Nav.Link href="/login" onClick={toggleSidebar}>
						Login
					</Nav.Link> */}
				</Nav>
			</div>

			{/* Backdrop to close the sidebar */}
			{isOpen && <div className="backdrop" onClick={toggleSidebar}></div>}
		</>
	);
}
