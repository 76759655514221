import React, { useRef, useEffect } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { Link } from 'react-router-dom';

const ImageSlider = () => {
	const videoRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			if (videoRef.current) {
				videoRef.current.play().catch((error) => {
					console.error('Autoplay failed:', error);
				});
			}
		}, 1000);
	}, []);

	return (
		<section>
			<div className="image-slider bg-black overflow-hidden">
				<div className="left-0 top-0 w-100 h-100 d-flex align-items-center text-center z-1 position-absolute">
					<div className="slider-line2 hidden lg:block">
						<img decoding="async" src="./assets/images/slider-line2.png" alt="slider-line2" />
					</div>
					<div className="container">
						<div className="row justify-content-center align-items-center">
							<div className="col-lg-7 col-md-7 text-center">
								<div className="slider-content mt-20 heading">
									<h2 className="lh-base font-bold headingFont">
										Unlock Your Potential with Precision and
										<br />
										<TypeAnimation
											style={{
												color: '#000',
												background: 'white',
												// fontWeight: 'bold',
											}}
											sequence={[
												'Master the Court',
												2000,
												'Perfect Your Game',
												2000,
												'Achieve Greatness',
												2000,
												() => {
													console.log('Sequence completed');
												},
											]}
											wrapper="span"
											cursor={true}
											repeat={Infinity}
										/>
									</h2>
									<p className="text-white">
										In the vibrant city of Hong Kong, known for its skyscrapers and bustling harbor, the Hong Kong International Tennis
										Academy (HKITA) stands as a beacon for young and aspiring tennis players.
									</p>
									<div className="slider-btn mt-4">
										<Link to="#" className="customBtn m-2">
											Register
										</Link>
										<Link to="#" className="customBtn active m-2">
											See Programs
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<video width="100%" muted autoPlay height="auto" className="mb-5" ref={videoRef} controls loop>
					<source src="/assets/videos/tennis.mp4" type="video/mp4" />
					{/* <source src="https://www.dltapi.ewizpro.com:9014/uploads/video/hkita-video.mp4" type="video/mp4" /> */}
					Your browser does not support the video tag.
				</video>

				{/* <Slider className="h-100 w-100 opacity-50" {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                className="w-[100%] object-cover h-[100vh]"
                alt={`Slide ${index + 1}`}
              />
            </div>
          ))}
        </Slider> */}
			</div>
		</section>
	);
};

export default ImageSlider;
