import React from "react";
import DatePicker from "react-date-picker";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
function CustomDatePicker({
  name,
  className,
  value,
  onChange,
  onBlur,
  validate,
  minDate,
}) {
  const convertToLocaleDate = (utcDateTimeString) => {
    const dateObj = new Date(utcDateTimeString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleChange = (date) => {
    const localDate = convertToLocaleDate(date.toISOString());
    onChange(localDate);
  };

  return (
    <div
      className={`position-relative ${
        validate && validate.length > 0 && "errorInput"
      }`}
    >
      <DatePicker
        name={name}
        id={name}
        className={className}
        format="dd-MM-yyyy"
        value={value ? new Date(value) : null}
        onChange={handleChange}
        onBlur={onBlur}
        yearPlaceholder="YYYY"
        monthPlaceholder="MM"
        dayPlaceholder="DD"
        minDate={minDate}
      />

      <style jsx="true">{`
      .react-date-picker__wrapper {
        border-width:0px;
      }
        button.react-date-picker__calendar-button.react-date-picker__button {
          background-size: 20px;
          background-repeat: no-repeat;
          background-position: calc(100% - 3px) center;
          background-color: #fff;
          background-image: ${!validate && value && value.length > 0
            ? "url(/assets/images/check_icon.png)"
            : validate && validate.length > 0
            ? "url(/assets/images/cross_icon.png)"
            : "none"};
        }
        button.react-date-picker__calendar-button.react-date-picker__button
          svg {
          opacity: ${!validate && value && value.length > 0
            ? "0"
            : validate && validate.length > 0
            ? "0"
            : "none"};
        }
      `}</style>
    </div>
  );
}

export default CustomDatePicker;
