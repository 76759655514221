/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
// import { AiOutlineShareAlt } from "react-icons/ai";

export default function SocialBar({ variant }) {
	const socialMedia = [
		// {
		// 	id: 1,
		// 	platform: 'Line',
		// 	value: 'https://line.me/R/ti/p/%40pzw3815i',
		// },
		{
			id: 2,
			platform: 'Call',
			value: '+852 9048 2810',
		},
		{
			id: 3,
			platform: 'Whatsapp',
			value: '+852 9048 2810',
		},
		{
			id: 6,
			platform: 'Facebook',
			value: '100057481478355',
		},
		{
			id: 6,
			platform: 'Facebook',
			value: 'monstertennis.tennis',
		},
		{
			id: 5,
			platform: 'Instagram',
			value: 'hkinternationaltennisacademy',
		},
		{
			id: 4,
			platform: 'Instagram',
			value: 'monstertennis',
		},
	];
	return (
		<>
			<div className={`socialMedia ${variant} rootBtn`}>
				{socialMedia &&
					socialMedia.map((i, index) => {
						return (
							<a
								key={index}
								href={
									i.platform === 'Whatsapp'
										? 'https://wa.me/' + i.value + '?text=Hello'
										: i.platform === 'Telegram'
										? 'https://t.me/' + i.value + '?text=Hello'
										: i.platform === 'Instagram'
										? 'https://www.instagram.com/' + i.value
										: i.platform === 'Facebook'
										? 'https://www.facebook.com/' + i.value
										: i.platform === 'Call'
										? 'tel:' + i.value
										: i.value
								}
								target="_blank"
								rel="noreferrer">
								<img src={`../assets/images/${i.platform}.png`} alt={i.platform} />

								<span className="socialLabel">&nbsp;{i.platform}</span>
							</a>
						);
					})}
			</div>
		</>
	);
}

SocialBar.propTypes = {
	// variant: PropTypes.oneOf(["primary", "secondary", "danger"]),
	variant: PropTypes.string,
};
