import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { HiArrowUp } from 'react-icons/hi';
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<footer className="bg-black-900 text-white py-8">
			<div className="container mx-auto px-4">
				<div className="flex flex-col md:flex-row justify-center items-center">
					{/* Follow Us Section */}
					<div className="flex space-x-4">
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.facebook.com/profile.php?id=100057481478355"
							className="text-gray-400 hover:text-white">
							<FaFacebookF className="text-2xl" />
						</a>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.instagram.com/hkinternationaltennisacademy/"
							className="text-gray-400 hover:text-white">
							<FaInstagram className="text-2xl" />
						</a>
					</div>

					{/* Back to Top */}
					<a href="#top" className="flex items-center text-gray-400 hover:text-white fixed  bottom-10 right-10">
						<HiArrowUp className="text-2xl mr-2" />
						Back to Top
					</a>
				</div>

				{/* Sitemap, Feedback, Contact Us */}
				<div className="mt-6 text-sm">
					<div className="flex items-center justify-center space-x-8">
						<Link to="/sitemap" className="text-gray-400 hover:text-white">
							Sitemap
						</Link>
						<a href="mailto:benny.lin@hkita.com?subject=HKITA Website Feedback" className="text-gray-400 hover:text-white">
							Feedback
						</a>
						<Link to="/contact-us" className="text-gray-400 hover:text-white">
							Contact Us
						</Link>
					</div>
					<hr />
					<div className="mt-4 md:mt-0 text-gray-500 text-center">
						<p>© {new Date().getFullYear()} The Hong Kong International Tennis Academy. All rights reserved.</p>
						<p>All registered trademarks belong to their respective owners.</p>
						<p>
							Powered by{' '}
							<a href="https://www.ewizpro.com/" target="_blank" rel="noreferrer" className="text-gray-400 hover:text-white">
								EwizPro
							</a>
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
