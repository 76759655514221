import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const OurStory = () => {
	return (
		<>
			<section className="testimonial_bg pt-[70px] pb-[100px] bg-slate-800 relative">
				<Container>
					<div className="row align-items-center pt-[70px]">
						<div className="col-md-12">
							<h6 className="text-white">Discover Our Journey</h6> {/* Subheading */}
							<h2 className="mb-3 text-white font-bold headingFont">The Story Behind HKITA</h2> {/* Heading */}
							<p className="text-white text-justify">
								In the vibrant city of Hong Kong, known for its skyscrapers and bustling harbor, the Hong Kong International Tennis Academy
								(HKITA) stands as a beacon for young and aspiring tennis players. Founded to foster local talent and place Hong Kong on the
								global tennis map, the academy's vision is clear: to nurture athletes capable of competing at the highest levels while
								emphasizing education, discipline, and personal growth.
							</p>
						</div>
					</div>
				</Container>
			</section>
			<Container className="mb-5 p-5 md:p-3 mt-[-70px] bg-white relative z-10 rounded-lg shadow-lg">
				<Row className="mb-5">
					<Col md={4} className="col-span-1 d-none d-md-block">
						<img alt="img" src="https://pbs.twimg.com/media/CKT3PfbWwAAR9LE.jpg" className="w-100 h-100 sticky top-[100px] rounded-lg shadow-lg" />
					</Col>
					<Col md={{ span: 7, offset: 1 }}>
						<h5 className="mt-3 mb-2 text-theme font-bold headingFont">Beginnings and Growth</h5>
						<p className="text-justify">
							The Hong Kong International Tennis Academy was established to address a growing need for a structured, elite tennis training program
							in the region. Hong Kong had long produced capable tennis players, but it lacked a centralized, world-class institution that could
							help young athletes develop from grassroots levels to professional careers. The HKITA was conceived as a solution, offering a
							comprehensive training environment that includes access to top-tier coaches, modern facilities, and international competitions.
						</p>
						<p className="text-justify">
							From its inception, the academy gained support from both public and private sectors, recognizing its potential to elevate Hong
							Kong’s presence in international tennis. The Hong Kong Tennis Association (HKTA) and various sponsors backed the academy, seeing it
							as an investment in the city’s sporting future.
						</p>
					</Col>
				</Row>
				<Row className="mb-5">
					<Col md={7}>
						<h5 className="mt-3 mb-2 text-theme font-bold headingFont">A Holistic Approach to Training</h5>
						<p className=" text-justify">
							What sets the HKITA apart is its commitment to a holistic approach to athlete development. The academy places a strong emphasis on
							not only physical training but also mental toughness, tactical intelligence, and character building. Training regimens are tailored
							to individual needs, whether athletes are focusing on technique refinement or preparing for international tournaments.
						</p>
						<p className="text-justify">
							The coaching team at HKITA is composed of experienced professionals, many of whom have played at the ATP or WTA levels. Their global
							experience brings diverse techniques and strategies into the training sessions, blending traditional coaching methods with modern
							sports science.
						</p>
					</Col>
					<Col md={{ span: 4, offset: 1 }} className=" d-none d-md-block">
						<img alt="img" src="https://pbs.twimg.com/media/CKT3PfbWwAAR9LE.jpg" className="w-100 h-100 sticky top-[100px] rounded-lg shadow-lg" />
					</Col>
				</Row>
				<Row className="mb-5">
					<Col md={4} className="col-span-1 d-none d-md-block">
						<img alt="img" src="https://pbs.twimg.com/media/CKT3PfbWwAAR9LE.jpg" className="w-100 h-100 sticky top-[100px] rounded-lg shadow-lg" />
					</Col>
					<Col md={{ span: 7, offset: 1 }}>
						<h5 className="mt-3 mb-2 text-theme font-bold headingFont">International Exposure</h5>
						<p className="text-justify">
							Recognizing that international experience is key to a successful tennis career, the academy ensures that its players participate in
							tournaments across Asia and beyond. Competing at international levels provides invaluable exposure to different styles of play and
							elevates the players’ competitive edge. As a result, HKITA has helped launch the careers of several players who have gone on to
							represent Hong Kong in prestigious global competitions such as the Davis Cup, Fed Cup, and various ATP and WTA tournaments.
						</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<h5 className="mt-3 mb-2 text-theme font-bold headingFont">Challenges and Achievements</h5>
						<p className="text-justify">
							Like any institution, the Hong Kong International Tennis Academy has faced challenges. One major hurdle has been balancing academic
							pursuits with the rigorous demands of professional tennis training. The academy addresses this by collaborating with schools and
							offering flexible academic programs that ensure athletes can pursue their education while training full-time.
						</p>
						<p className="text-justify">
							The academy's efforts have already paid off. Several players who have passed through its ranks have risen in the international
							tennis rankings, and HKITA's influence has begun to ripple throughout the region. Hong Kong's young tennis stars now have a clear
							pathway to global success, thanks in large part to the support and training they receive from the academy.
						</p>
					</Col>
					<Col md={{ span: 4, offset: 1 }} className="d-none d-md-block">
						<img alt="img" src="https://pbs.twimg.com/media/CKT3PfbWwAAR9LE.jpg" className="w-100 h-100 sticky top-[100px] rounded-lg shadow-lg" />
					</Col>
				</Row>
				<Row className="mb-5">
					<Col md={4} className="col-span-1 d-none d-md-block">
						<img alt="img" src="https://pbs.twimg.com/media/CKT3PfbWwAAR9LE.jpg" className="w-100 h-100 sticky top-[100px] rounded-lg shadow-lg" />
					</Col>
					<Col md={{ span: 7, offset: 1 }}>
						<h5 className="mt-3 mb-2 text-theme font-bold headingFont">Looking Ahead</h5>
						<p className=" text-justify">
							The future for the Hong Kong International Tennis Academy is bright. With plans to expand its facilities and grow its partnerships
							with international tennis bodies, the academy aims to continue its role as a hub for tennis excellence in Asia. Its vision is not
							just about producing great athletes but also creating role models who will inspire future generations of tennis players in Hong Kon
							g and beyond.
						</p>
						<p className="text-justify">
							In this way, the Hong Kong International Tennis Academy is much more than just a training facility; it is a symbol of the city’s
							potential and ambition on the global sporting stage.
						</p>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default OurStory;
