import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function CustomPhoneInput({ validate, className, countryCodeEditable, country, value, onChange }) {
  const style = {
    width:'100%',
    backgroundSize: 18,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "calc(100% - 5px) center",
    backgroundColor: "#fff",
    backgroundImage:
      !validate && value && value.length > 0
        ? "url(/assets/images/check_icon.png)"
        : validate && validate.length > 0
          ? "url(/assets/images/cross_icon.png)"
          : "none",
  };

  return (
    <div className={`position-relative`}>
      {/* Uncomment if needed
      {validate && validate.length > 0 && (
        <AiOutlineCloseCircle className="position-absolute  right-[10px] top-[6px] z-0 bg-white text-[22px]" style={{ color: "red" }} />
      )}
      {!validate && value && value.length > 0 && (
        <AiOutlineCheckCircle className="position-absolute  right-[10px] top-[6px] bg-white text-[22px]" style={{ color: "green" }} />
      )}
      */}
      <PhoneInput
        style={style}
        specialLabel=""
        inputClass={`${className} ${validate && validate.length > 0 && "errorInput"}`}
        countryCodeEditable={countryCodeEditable}
        country={country}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
