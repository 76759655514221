import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const ContactUs = () => {
	return (
		<>
			{/* Background Section */}
			<section className="contact_bg pt-[70px] pb-[100px] bg-slate-800 relative">
				<Container>
					<div className="row align-items-center pt-[70px]">
						<div className="col-md-12">
							<h6 className="text-white">Get in Touch</h6> {/* Subheading */}
							<h2 className="mb-3 text-white font-bold headingFont">Contact Us</h2> {/* Heading */}
							<p className="text-white text-justify">
								Whether you have a question about our academy, want to inquire about programs, or simply need more information, feel free to
								contact us. We're here to help and provide you with the best support possible.
							</p>
						</div>
					</div>
				</Container>
			</section>

			{/* Contact Form Section */}
			<Container className="mb-5 p-5 md:p-3 mt-[-70px] bg-white relative z-10 rounded-lg shadow-lg">
				<Row>
					{/* <Col md={6}>
            <h5 className="mt-3 mb-2 text-theme font-bold headingFont">Send us a message</h5>
            <Form>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter your name" />
              </Form.Group>

              <Form.Group controlId="formPhone" className="mt-3">
                <Form.Label>Daytime Phone</Form.Label>
                <Form.Control type="text" placeholder="Enter your phone number" />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" placeholder="Enter your email" />
              </Form.Group>

              <Form.Group controlId="formMessage" className="mt-3">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={4} placeholder="Enter your message" />
              </Form.Group>

              <Button className="mt-4" variant="primary" type="submit">
                Submit
                    </Button>
                    
                    <p className='mt-4'>Please send all inquiry to <a href="mailto:benny.lin@hkita.com">benny.lin@hkita.com</a></p>
            </Form>
          </Col> */}

					<Col md={{ span: 5, offset: 1 }}>
						<h5 className="mt-3 mb-2 text-theme font-bold headingFont">Contact Information</h5>
						<p>
							<strong>Benny Lin</strong>
							<br />
							Mobile/WhatsApp: +852 9048 2810
							<br />
							Email: <a href="mailto:benny.lin@hkita.com">benny.lin@hkita.com</a>
						</p>

						{/* <p>
							<strong>Masahiko Yoshida</strong>
							<br />
							Mobile: 6293 7288
							<br />
							Email: <a href="mailto:masahiko.yoshida@hkita.com">masahiko.yoshida@hkita.com</a>
						</p>

						<p>
							<strong>Vuk Zelunka</strong>
							<br />
							Email: <a href="mailto:vuk.zelunka@hkita.com">vuk.zelunka@hkita.com</a>
						</p> */}

						<h5 className="mt-4 text-theme font-bold headingFont">Our Address</h5>
						<p>
							Hong Kong International Tennis Academy
							<br />
							Unit 3, 9th Floor, New Tech Plaza
							<br />
							34 Tai Yau Street, San Po Kong, Kowloon, Hong Kong
							<br />
							Tel: (852) 9662 8888
						</p>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default ContactUs;
