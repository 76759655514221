import React from 'react';
import { Container, Nav } from 'react-bootstrap';

const MonsterTennis = () => {
	return (
		<section className="testimonial_bg md:py-[40px] py-[100px] bg-blue-200 relative">
			<Container>
				<div className="row align-items-center justify-center max-w-lg mx-auto">
					<img src="/assets/images/monster_tennis/monster-tennis-logo.png" alt="Monster Tennis Logo" className="w-96" />
					<p className="text-dark text-center font-comfortaa">
						Monster Tennis is a tennis specific physical play programme designed for ages 3 and above.
					</p>
				</div>
				<Nav.Link className="text-main text-center uppercase hover:underline hover:underline-offset-1" href="/monster-tennis">
					Read More
				</Nav.Link>
			</Container>
		</section>
	);
};

export default MonsterTennis;
