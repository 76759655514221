export const players = [
	{
		name: 'Hannah Shen',
		country: 'Hong Kong',
		achievements: ['Brown University'],
		feedback: `<p>I highly recommend Benny Lin to any student athlete who is seriously considering playing university tennis in the United States. </p>
		
		<p>I began training with Benny and his team when I just started playing tennis at the age of six. It was with Benny and his team that I was inspired to be the best tennis player that I could be. Over the years, Benny gave me direction and advice on what a player needs to do and work on to get to the required tennis levels for United States university tennis.  Benny guided me through my tennis recruiting process, and I am now going to my dream school, Brown University.</p>
		
		<p>I am very thankful for all of the training, advice and guidance that Benny provided to me over the past many years. I truly appreciate all of his hard work and dedication. He puts his entire heart into his efforts, and is truly extraordinary in his experience and skills.</p>

		`,
		avatar: './assets/images/Players/HKITA_Testimt_00011_Hannah.jpeg',
	},
	{
		name: 'Ryan Ho',
		country: 'Hong Kong',
		achievements: [],
		feedback: `<p>There is no maxim, in my opinion, given my experiences in both my academic and tennis career, that illustrates the success of the College Prospect Showcase better than the one that states that our lives are defined by the opportunities we take and the opportunities we don't. Choosing to go with Benny to the US and attend the Showcase at Yale is precisely an example of a decision I have made that has shaped my life, presenting me with more opportunities with tennis than I could ever have fathomed years ago.</p>
		
		<p>Benny's relentless dedication towards my aspirations of playing college tennis not only served to open new doors, but asserted the fact that the countless tiresome hours I spent practicing and studying were directed towards a greater cause - a collective academic experience in the United States that will allow me to satiate both my academic and athletic hungers.</p>
		
		<p>The first-hand experience of visiting schools and meeting personally with a wide range of coaches from prestigious academic institutions during the course of the trip provided the necessary insight and support that I needed for my applications. My recent acceptance into the University of Chicago is reflective of Benny's support and the advantages of his established connection with the coach and not to mention, Benny's understanding of the college tennis world, too.</p>

		<p>For those who may already have the necessary athletic and academic abilities to combine a high level education with high level tennis, both Benny's commitment and the Showcase itself will provide the necessary 'kick' that will hopefully score you the opportunity of a lifetime."</p>
		`,
		avatar: './assets/images/Players/HKITA-Player--Ryan.jpg',
	},
	{
		name: 'Kenji Low',
		country: 'Hong Kong',
		achievements: ['Cornell University'],
		feedback: `<p>The trip to the US, apart from being a lot of fun, was a life changing experience for me. It opened up so many opportunities for me as a student and as tennis player and it definitely widened my perspective on pursuing tertiary education in the US.</p>
		
		<p>The College Prospect Showcase held in Yale University, New Haven, Connecticut was a 2-day event held primarily to provide potential students-athletes an opportunity to play in front of college coaches from various top institutions. Prior and following the 2-day showcase (the showcase was sandwiched between visits to various schools), we spent our time touring campuses around the East Coast. We visited a total of 6 Ivy League schools and 2 top Liberal Arts Colleges during our stay.</p>
		
		<p>It was amazing how Benny actually found any time to sleep at all with all that driving! Throughout the 10-day period we were in the US, Benny spent much of his time getting as many coaches from various prestigious institutions to watch us play. We were given an opportunity to meet the coaches at all the schools we visited and listen to insights on receiving an Ivy League education as well as playing college tennis. During these visits, it was clear that Benny was committed to ensuring we all had an opportunity to play college tennis at a top school when he constantly advocated for our cause.</p>
		
		<p>Without him backing me, I very much doubt that I would have attracted as much attention as I did during my stay in the US and establish a connection with the head coach from Cornell University. I can safely attribute a huge bulk of the success of my application to Cornell to Benny. He is, without a doubt, the reason I have an opportunity at college tennis in the US.</p>`,
		avatar: './assets/images/Players/HKITA_Testimt_0009_LOW-Kenji.jpg',
	},
	{
		name: 'Heather Hills',
		country: 'Hong Kong',
		achievements: ['Inter-Primary School 2008 Champion'],
		feedback: `<p>I started to be coached by Benny and Coach Lin since May 2007. Within just over year, I have learned and improved so much that I won my first title in Novice for 10 and under and the Champion in Hong Kong Inter-Primary School Tennis Competition 2007-2008 in June 2008.</p>
		
		<p>I would recommend any kids who like to play tennis to join Benny Lin HKITA; it would boost your ability and confidence. The coaching and training sessions are so fruitful and enjoyable. Oh, it is also so awesome!!</p>

		<p><strong>Prof. Jane Hao - Mother of Heather Hills</strong></p>
		
		<p>Benny Lin and Coach Lin coaching techniques and training styles are outstanding and cater for beginner right through to professional levels. The tennis drills are unique. The sessions are not only a great way to improve your game but are also fun and hugely improve your fitness.</p>
		
		<p>Benny Lin and Coach Lin are absolutely brilliant with children and are able to get the maximum potential and skill out of each child he coaches.</p>`,
		avatar: './assets/images/Players/HKITA_Testimt_0008_Heather_Hills.jpg',
	},
	{
		name: 'Yung-Jan Chan',
		country: 'Taipei',
		achievements: ['Australian Open Women’s Doubles Finalist', 'Highest ITF World Junior Ranking No. 2'],
		feedback: `<p>Coach Lin was my childhood coach who taught me how to play tennis. He was the only coach that I worked with who knew the importance of fundamentals. Even until today, Coach Lin’s influence and hard work still remain in my character as a tennis player and a person. Not only he was a good coach, he was also a caring and nurturing person who was very dedicated in my development as a tennis player and as a child. I learned a lot from Coach Lin as he guided me during the most important phase of my development years, and he is still my mentor.</p>`,
		avatar: './assets/images/Players/HKITA_Testimt_0007_Chan_Yun_Jan.jpg',
	},
	{
		name: 'Andrew Hamar',
		country: 'Wales',
		achievements: ['All American Wake Forest University'],
		feedback: `<p>Raphael Maurer is somebody who I have worked with for several years, traveling around the USA playing ITF's and Futures for a couple of months in 2003. He helped me obtain my first ATP point whilst traveling together, in only my 4th future, and similarly helped me make into Orange Bowl in my final year as a junior. Even though I am 5000 miles away, we still talk regularly and he still helps me with my game. In terms of coaching, I have never met somebody as positive and uplifting, and I can honestly say he boosted my performance significantly, working not only my technical and tactical game, but also my mental approach to tennis. It's an impressive feat when somebody is able to manage this in my opinion. As I soon graduate from Wake Forest University having made the last 16 in all Americans and been ranked as high as 35, I hope to continue working with Raphael in whatever aspect he is able to help. Ever since he worked with me since he coached me at Van Der Meer tennis academy he has always been a coach whom I have high regard for both on and off the tennis court, as he works his players hard in both areas. It has always been the utmost pleasure to work with Raphael Maurer.</p>`,
		avatar: './assets/images/Players/HKITA_Testimt_0006_Andrew_Hamar.jpg',
	},
	{
		name: 'Ronald Chow',
		country: 'Hong Kong',
		achievements: ['US College Student Athlete Scholarship Recipient', 'Former HK Top Ranked Junior', 'ITF World Ranked player'],
		feedback: `<p>Benny came to Hong Kong 5 years ago. He was the head coach of the junior squad. I was one of his students in the past. He started teaching the Talent Group and the National Training Squad and I was one of the team members at that time. Benny is a coach who is very good at analyzing ones’ tennis ability and skills. He was able to see what I was doing wrong and what I needed to work the most. He was very motivating to me and has been an inspiration in my previous 5 years of tennis. Benny has been such a great mentor to me not just on the court, but off the court as well. He has taught me how to become more of a mature person. Benny was one of the main reasons that I was able to obtain a scholarship from Azusa Pacific University. Benny has helped me to develop a strong work ethic on and off the court. He has helped me to develop strong relationships with other team members. He has helped motivate me to become a better person, in terms of relating to other people. I have learned also to work hard on the track and in the weight room to help improve my game. There are still many things I can learn from Benny due to his good values and character.</p>`,
		avatar: './assets/images/Players/HKITA_Testimt_0005_Ronald_Chow.jpg',
	},
	{
		name: 'Zhang Ling',
		country: 'Hong Kong',
		achievements: ['ITF World Junior Ranking No. 18'],
		feedback: `<p>I would like to say how much I have appreciated Raphael's coaching during these few years. With his help, I made big improvements not only on technical skills but also learned how to train physically, take good care of my body, and be stronger mentally. He always deeply understands what the players need during daily practices of the game. In addition, he really cares about players and has a lot of respect for them and what they think. I believe he will have a great contribution to the local development of tennis.</p>`,
		avatar: './assets/images/Players/HKITA_Testimt_0004_Zhang_Ling.jpg',
	},
	{
		name: 'Daniel Drew',
		country: 'USA',
		achievements: ['Former Under 14 USA National Champion', 'ITF World Ranking of 61'],
		feedback: `<p>I met Raphael in Kansas City when he was coaching at a local country club. I started working with Raphael when I was thirteen years old. Raphael was incredibly good at picking out my weaknesses and not only working the physical aspects of my game but also the mental side. He improved my work ethic on and off the court. I have worked with Raphael when I was a top USTA player and then again as a top 100 ITF player. I believe he is very knowledgeable as a high level coach and was a great guy to work with.</p>`,
		avatar: './assets/images/Players/HKITA_Testimt_0003_Drew_Daniel.jpg',
	},
	{
		name: 'Gilbert Wong',
		country: 'Hong Kong',
		achievements: ['US College Student Athlete Scholarship Recipient', 'Former HK Top Ranked Junior', 'ITF World Ranked player'],
		feedback: `<p>It is my great pleasure to write about my coach, Benny Lin. Benny was one of the best coaches in my tennis career. His training program was very professional that had rectified my weaknesses and built up my toughness attitude. He was patient and friendly coach who treated everybody the same. Until now, Benny’s words and advices are still in my mind. These words are very useful in my character development as a successful person and a professional tennis player. Besides, he helped me to contact coaches in the States to get scholarship in universities even though after I was promoted to the Sports Institute. I have learnt to be mature under Benny’s leadership and I am very happy to be one of his students and to have Benny as my mentor.</p>`,
		avatar: './assets/images/Players/HKITA_Testimt_0002_Gilbert_Wong.jpg',
	},
	{
		name: 'Leung Lok-Sze',
		country: 'Hong Kong',
		achievements: ['No. 1 in Under 14 Girls’ Division', 'World Junior Team player'],
		feedback: `<p>I was under the coaching by Benny since I was 10. He first gave me an impression that he was very disciplined, serious, tough and rarely smiled. But after a few months, I realized that he was a very warm-hearted and caring person. When we went to the U.S. during summer in 2003 for a month participating in some local tournaments, he worked so hard that he had only few hours of sleep every night. He was the driver, tour guide, coach and baby sitter. I was very impressed by his hard work and dedication to Hong Kong junior tennis development. Throughout these years, he taught me many things about tennis, but I consider the most important two things are "never give up fighting spirit" and "respectable sportsmanship". By the way, he still owes me some ice cream.</p>
		
		<p>Yes, it's true. It takes two years of preparation, or even more, to be accepted to a college of your choice.</p>
		<p>After going through the SATs, the showcase, weeks of essay-writing, I have come into conclusion that college search for tennis players, or student athletes in general is - a process where you try to find a school that "best fits" you. In other words, identifying and getting accepted to a school that is in line with your tennis level and academic standard, and enables you to strive in both areas.</p>
		<p>Focus on tennis, I must say that the Showcase has exposed me to more than 60 coaches, allowing me demonstrate my tennis skills; at the same time, it served as a platform for the coaches to get to know more about my game beyond the black and white resume. The two-day event at Yale is important to players like me who don't play a lot of ITFs or other international tournaments. I have to thank coach Benny for taking me to the Showcase while also guiding and helping me to connect and build an important and crucial network with different coaches, which contributed to my eventual acceptance to Middlebury College.</p>
		<p>The college application process is one of those once-in-a-lifetime experience that is strenuous, and frustrating at times, but definitely rewarding and worth while.</p>`,
		avatar: './assets/images/Players/HKITA_Testimt_0001_Leung_Lok_Sze.jpg',
	},
	{
		name: 'Geraldine Leong',
		country: 'Hong Kong',
		achievements: ['CRC Girls’ Singles Champion', 'Junior Fed Cup World Finals player'],
		feedback: `<p>Benny was a total stranger to me about 5 years ago. But I soon got to know him well through Talent Group, a training program he started through Hong Kong Tennis Association. He is easy-going and humorous... that is when it is not fitness time. He is extremely tough during fitness time, and never lets us get away with it. But that was how I grew stronger physically and mentally, and there were huge improvements, and it had definitely shown through my match play. As time went by, he arranged all sorts of programs, enabling us to broaden our vision in the tennis world – from hard courts in the US to clay courts in Spain, it was a marvelous experience. Benny is a coach that cares about and is supportive of our tennis developments. During the Fed Cup competition, I could not have asked for a better captain as he pushed all of us to our limits, and we achieved the unthinkable. I believe he is definitely the coach all players are looking for if you have the passion for tennis!</p>`,
		avatar: './assets/images/Players/HKITA-Player-Leong-Geraldine.jpg',
	},
	{
		name: 'Lorraine Cheung',
		country: 'Hong Kong',
		achievements: ['California State University Northridge'],
		feedback: `<p>As I grow up, my goals keep on changing. When I was 13, I wished to represent Hong Kong at World Junior Tennis and to become the best in my age group. When I was 15, I desired for a spot on the Junior Fed Cup team and to perform well in international tournaments. When I was 17, I wanted to play college tennis.</p>
		
		<p>With Benny’s guidance, my goals turned into reality. If it wasn’t him, I wouldn’t be here right now-  attending university in the States and continue being a student athlete.</p>
		
		<p>Throughout the whole college recruiting process, Benny not only introduced me to a lot of coaches from various types of institutes, but he also taught me to find the right college for myself. Sometimes it’s not about getting into the best college, but is about being the best individual you can be. Through being a student-athlete, I have developed my independence, patience and persistence; through playing tennis, I have benefited to excel as a person with both training of the body and the mind. Studying and playing at California State University, Northridge, a NCAA Division 1 school, will surely keep a balance between my academics and athletics career.</p>
		
		<p>Without Benny’s assistance, I would be somewhere else right now - perhaps staying in my room all day long worrying about my HK A level exams. What I learn from being a student-athlete will surely assist me down the path towards success. I want to take this chance to thank Benny for all these years of inspiration and the opportunities he’s given me!</p>`,
		avatar: './assets/images/Players/HKITA_Testimt_00010_Lorraine.jpg',
	},
	{
		name: 'Sher Pui Wing',
		country: '',
		achievements: [],
		feedback: `<p>Benny has been my coach since I was 12. He not only teaches me to become a better person on court and off court, he also guided me throughout my college application process. I went to the Yale showcase with him in 2009 and it was a life changing experience for me. During the showcase, I learnt more about college tennis after meeting with coaches from different colleges and played against other players. After talking to coaches of different top ranked colleges, I learned more about their tennis programs and academic requirements that are needed for application. After the showcase, I had a general list of schools that I was interested in and was capable applying to</p>
		<p>Additionally, the experience from the showcase benefited me a lot as I had made a great impression on coaches and obtained information that helped me to prepare works for my application. I was motivated to work hard and reach the requirements when I came back to Hong Kong. Benny has provided valuable advice to me during the showcase and also throughout my application process. He knows a lot about college tennis and knows what area we should focus on to make a good impression to other coaches. He is definitely the person that I would approach when I have questions about college tennis. I am really glad that I took the trip with Benny to the showcase. It was a remarkable experience for me and helped me make my choice to attend Seton Hall University on full scholarship! I believe this trip could not have been successful without the guidance of Benny.</p>
		`,
		avatar: './assets/images/Players/sherpuiwing.png',
	},
	{
		name: 'Lai Ting Yee',
		country: '',
		achievements: [],
		feedback: `<p>I joined the Yale Showcase in 2009, so it’s hard to recall the exact details, but still I can share a few things. When I signed up for the showcase, a few coaches started to email me to introduce their schools and one in particular, Carnegie Mellon University, caught my attention because it is a top 20 US school. When I got there and played a few matches, I didn’t play so well because there were many talented players. When I got back to Hong Kong, I contacted the CMU coach again and he was very straightforward to say that he saw me playing and felt that I wasn’t competitive enough for his team. So, I guess what I’m trying to say is that, the experience is helpful if you’re a good player and can impress the coach. If not, at least you know where you stand and it’ll help you plan another back up strategy. One word of advice is that, prepare well for the Showcase. Don’t just show up and mess up.</p>
		<p>The rest of the trip was fun because Benny took good care of us and showed us around many famous colleges. We also got to play on a few college campuses that Benny has connection with. It was a great experience and I had a lot of fun.</p>
		`,
		avatar: '',
	},
	{
		name: 'Lee Sojung',
		country: '',
		achievements: [],
		feedback: `<p>I don’t think there is any way to express my gratitude for being given the chance to go to the College Prospect Showcase. So, I sat in front of my computer for several minutes, trying to formulate my thoughts into words. Hopefully, this gets the message across.</p>
		<p>I met Benny Lin through the HKTA Talent Group program. I remember he was the seemingly strict coach that we saw maybe twice a week. But during the years I trained with him, I got to know him better, and the stern quality about him unraveled. When I applied to boarding schools in America, he was one of my strongest supporters, as he was with my college application process.</p>
		<p>The trip a few players and I took with Benny to the States proved to be a fun yet fruitful experience. We productively traveled from one prestigious school to another, and met with many coaches along the way. The college visits themselves hugely assisted me later on when I was writing my applications. All I needed to do was pull up an experience I had at each school, and my essay was more personal and less distant. On a side note, those that haven’t experienced a true road trip before will surely treasure the pit stops and the once in a while navigational problems :-)</p>
		<p>The two-day Yale Showcase was almost nerve-racking. It was almost impossible to not feel the pressure building up. After all, there were dozens of other tennis players there, all competing for the same thing that I wanted: admission. But if there is anything that Benny is good for (and trust me, there is a lot), it’s his intense support and well-rounded connections in the college tennis world.</p>
		<p>The college process can be a very frustrating experience. It’s one of the times in our lives when we need to find the best place for us, the school that fits. I want to thank Benny for guiding me through the summer of 2010. The trip to America was necessary for giving me valuable insight and feedback on many prestigious schools, and was without a doubt a worthwhile experience.</p>
		`,
		avatar: './assets/images/Players/Testimonial_sojung.jpg',
	},
];
