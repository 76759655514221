import React from 'react';
import { Container, Nav } from 'react-bootstrap';

export default function ProgramSection() {
	return (
		<section className="portfolio-section md:py-[100px] py-[50px] bg-white" data-aos="fade-up">
			<Container>
				<div className="row md:mt-5 mt-0 align-items-center">
					<div className="col-lg-5 heading">
						<h6 className="text-theme ">HKITA’s U.S. College Tennis Story</h6>
						<h2 className="mb-4 font-bold headingFont">The Court of Opportunities: How Tennis Can Shape Prestigious College Admissions</h2>
						<p className="text-justify">
							As the college admissions landscape becomes increasingly competitive, students are constantly searching for ways to stand out. While
							academics, test scores, and extracurricular activities are essential, athletic achievements —specifically in tennis—can be a
							powerful differentiator, especially when applying to prestigious colleges.
						</p>

						<Nav.Link className="text-main uppercase hover:underline hover:underline-offset-1" href="/court-of-opportunities">
							Read More
						</Nav.Link>
						<div className="mt-2">
							<a
								href="https://forms.gle/E2h1B9y92JJE57W28"
								target="_blank"
								rel="noreferrer"
								className="text-theme normal-case font-semibold pt-4">
								If you're interested, tell us more about you.
							</a>
						</div>
					</div>
					<div className="col-lg-7">
						<div className="row mt-5 g-3">
							{/* Image Grid */}
							<div className="col-lg-5 col-12">
								<div className="program_card">
									<img
										src="./assets/images/College/us-1.jpg"
										alt="Portfolio 1"
										className="w-100 sm:h-[200px] md:h-[400px] object-cover rounded shadow-sm"
									/>
									<div className="sectionInfo">
										<h5>Princeton and Haveford campus tour</h5>
										<p>with the head coaches.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-7 col-12">
								<div className="program_card">
									<img
										src="./assets/images/College/us-2.jpg"
										alt="Portfolio 2"
										className=" w-100 sm:h-[200px] md:h-[400px] object-cover  rounded shadow-sm"
									/>
									<div className="sectionInfo">
										<h5>Princeton and Haveford campus tour</h5>
									</div>
								</div>
							</div>

							<div className="col-lg-7 col-12 mb-4">
								<div className="program_card">
									<img
										src="./assets/images/Gallery/college/college-3.jpg"
										alt="Portfolio 3"
										className=" w-100 sm:h-[200px] md:h-[400px] object-cover  rounded shadow-sm"
									/>
									<div className="sectionInfo">
										<h5>Princeton and Haveford campus tour</h5>
										<p> Q and A inside Albert Einstein’s classroom.</p>
									</div>
								</div>
							</div>

							<div className="col-lg-5 col-12 mb-4">
								<div className="program_card">
									<img
										src="./assets/images/College/us-4.jpg"
										alt="Portfolio 4"
										className=" w-100 sm:h-[200px] md:h-[400px] object-cover  rounded shadow-sm"
									/>
									<div className="sectionInfo">
										<h5>Princeton and Haveford campus tour</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</section>
	);
}
