import React, { useState, useEffect } from 'react';
import { Col, Container, Form, Row, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Label from '../../component/Label';
import CustomInput from '../../component/CustomInput';
import CustomDatePicker from '../../component/CustomDatePicker';
import CustomSelect from '../../component/CustomSelect';
import LtSelect from '../../component/LtSelect';
import { useNavigate } from 'react-router-dom';
import doAPI from './../../api';
import { toast } from 'react-toastify';
import { HiOutlineEye, HiOutlineEyeOff, HiOutlineRefresh } from 'react-icons/hi';
import PhoneInput from '../../component/CustomPhoneInput';
import cityData from '../../data/city.json';
import stateData from '../../data/state.json';
import countryData from '../../data/country.json';
import Swal from 'sweetalert2';
import { capitalizeFirstLetter } from '../../component/utils';
import ReCAPTCHA from 'react-google-recaptcha';

const playerRegisterSchema = Yup.object().shape({
	first_name_en: Yup.string().required('First name is required'),
	last_name_en: Yup.string().required('Last name is required'),
	password: Yup.string()
		.required('Password is required')
		.matches(
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/,
			'Password must contain 8 characters, one uppercase letter, one lowercase letter, one number and one special case character'
		),
	confirmPassword: Yup.string()
		.required('Confirm Password is required')
		.oneOf([Yup.ref('password'), null], 'Passwords must match'),
	gender: Yup.string().required('Gender is required'),
	dob: Yup.date().required('Date of Birth is required'),

	email: Yup.string().email('Invalid email address').required('Email is required'),
	mobile: Yup.string().required('Mobile number is required'),

	address: Yup.string().required('Address is required'),
	parent_first_name: Yup.string().required('First name is required'),
	parent_last_name: Yup.string(),
	parent_email: Yup.string().email('Invalid email address').required('Email is required'),
	parent_mobile: Yup.string().required('Mobile number is required'),
	relation_type: null,

	country_id: Yup.mixed().required('Country is required'),
	state_id: Yup.mixed().required('State is required'),
	city_id: Yup.mixed().required('City is required'),
	// center_id: Yup.mixed().required("Center is required"),
});

export default function Registration() {
	const sitekey = process.env.REACT_APP_SITE_KEY;
	const [isLoading, setIsLoading] = useState(false);
	const [showPwd, setShowPwd] = useState(false);
	const [showConfPwd, setShowConfPwd] = useState(false);
	const [country, setCountry] = useState([]);
	const [cities, setCities] = useState([]);
	const [state, setState] = useState([]);
	const [countryCode, setCountryCode] = useState('');
	const [centerError, setCenterError] = useState('');
	const [captcha, setCaptcha] = useState([]);
	const [centers, setCenters] = useState([{ label: 'Select Center', value: 0 }]);
	const [recaptchaError, setRecaptchaError] = useState(false);

	const navigate = useNavigate();

	const [emailList, setEmailList] = useState();
	// eslint-disable-next-line no-unused-vars
	const [showAddForm, setShowAddForm] = useState(false);
	const handleCloseAddForm = () => setShowAddForm(false);
	const handleShowAddForm = () => setShowAddForm(true);

	const [programList, setProgramList] = useState([]);
	const [frontend, setFrontend] = useState();
	const [allCenters, setAllCenters] = useState([]);

	const recaptchaRef = React.createRef();
	function onChange(value) {
		setCaptcha(value);
	}
	// React.useEffect(() => {
	//   setLoadingCenters(true);
	//   doAPI
	//     .getData("academy-center/active-list")
	//     .then((res) => {
	//       setCenterError("");
	//       const response = res.data;
	//       if (response.status) {
	//         setLoadingCenters(false);
	//         setAllCenters(response.data);
	//         setCenters([]);
	//         response.data.map((i, index) => {
	//           let centerObj = {};
	//           centerObj.value = i.id;
	//           centerObj.label = i.name_en;
	//           setCenters((old) => [...old, centerObj]);
	//         });
	//       }
	//     })
	//     .catch((error) => {
	//       setCenterError(
	//         "Seems like there is an issue, please contact your admin!"
	//       );
	//       setLoadingCenters(false);
	//       console.log(error);
	//     });
	// }, []);

	const formik = useFormik({
		validateOnBlur: true,
		validateOnChange: true,

		initialValues: {
			first_name_en: '',
			last_name_en: '',
			password: '',
			confirmPassword: '',
			gender: '',
			dob: '',
			address: '',
			parent_first_name: '',
			parent_last_name: '',
			parent_email: '',
			parent_mobile: '',
			relation_type: null,
			program_id: null,
			email: '',
			mobile: '',
			country_id: '',
			state_id: '',
			city_id: '',
			center_id: 26,
		},
		onSubmit: (values, { resetForm }) => {},
		validationSchema: playerRegisterSchema,
	});

	const fetchFrontendData = () => {
		doAPI.getData(`customize-frontend`).then((res) => {
			const response = res.data.data;
			if (res.data.status) {
				if (response?.academy_countries[0]) {
					formik.setFieldValue('country_id', response?.academy_countries[0]?.country_id);
					setCountryCode(response?.academy_countries[0]?.country?.shortname);
				}
				setFrontend(response);
			}
		});
	};

	const fetchEntry = () => {
		doAPI.getData(`program/featured?limit=200`).then((res) => {
			const response = res.data.data.content;
			if (res.data.status) {
				setProgramList([]);
				response.map((i, index) => {
					let centerObj = {};
					centerObj.value = i.id;
					centerObj.label = i.name_en;
					setProgramList((old) => [...old, centerObj]);
				});
			}
		});
	};

	React.useEffect(() => {
		fetchEntry();
		fetchFrontendData();
	}, []);

	const submitForm = () => {
		if (captcha.length !== 0) {
			// setFormValues(formik.values);
			setIsLoading(true);
			doAPI.postData('player/register/partial', formik.values).then((res) => {
				console.log(res);
				if (res?.response?.status === 302) {
					setEmailList(res.response.data);
					handleShowAddForm();
				}

				if (res.status) {
					toast('Player has been registered', 'success');
					setIsLoading(false);
					formik.resetForm();

					Swal.fire({
						title: 'Successful!',
						text: 'You have registered successfully!',
						icon: 'success',
					});
				} else {
					console.log('error');
					toast(res.message[0].message, 'error');
					setIsLoading(false);
				}
				if (res?.response?.status === 406) {
					console.log(res);
				}
			});
			setRecaptchaError(false);
		} else {
			setRecaptchaError(true);
		}
	};

	React.useEffect(() => {
		setCountry([]);
		countryData &&
			countryData.map((i) => {
				const singleCountry = {};
				singleCountry.label = i.name;
				singleCountry.value = i.id;
				setCountry((old) => [...old, singleCountry]);
			});
	}, []);

	React.useEffect(() => {
		setState([]);
		stateData &&
			stateData.map((i) => {
				if (i.country_id === formik.values.country_id) {
					const singleState = {};
					singleState.label = i.name;
					singleState.value = i.id;
					setState((old) => [...old, singleState]);
				}
			});
	}, [formik.values.country_id]);

	React.useEffect(() => {
		setCities([]);
		cityData &&
			cityData.map((i) => {
				if (i.state_id === formik.values.state_id) {
					const singleCity = {};
					singleCity.label = i.name;
					singleCity.value = i.id;
					setCities((old) => [...old, singleCity]);
				}
			});
	}, [formik.values.state_id]);

	React.useEffect(() => {
		if (centerError !== '') {
			Swal.fire({
				title: 'Oops! Please try refreshing.',
				text: 'It seems there is an unexpected problem. If the problem persists, please contact your admin!',
				timer: 5000,
			});
		}
	}, [centerError]);

	useEffect(() => {
		formik.setFieldValue('country_id', allCenters.filter((i) => i.id === formik.values.center_id)[0]?.country_id);
		formik.setFieldValue('state_id', allCenters.filter((i) => i.id === formik.values.center_id)[0]?.state_id);
		formik.setFieldValue('city_id', allCenters.filter((i) => i.id === formik.values.center_id)[0]?.city_id);
	}, [formik.values.center_id]);

	const addToFormik = (name, value) => {
		formik.setFieldValue(name, capitalizeFirstLetter(value));
	};

	return (
		<section
			className="testimonial_bg pt-[70px] pb-[100px] bg-slate-800 relative"
			style={{
				// background: "url('https://pbs.twimg.com/media/CKT3PfbWwAAR9LE.jpg')",
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}>
			<Container>
				<div className="row align-items-center pt-[70px]">
					<div className="col-xl-8 mx-auto p-3 lg:p-5">
						<Form className="bg-white rounded-lg border p-md-4 p-3" onSubmit={formik.handleSubmit}>
							<div className=" text-center p-3">
								<h6 className="">Registration</h6>
								<h2 className="mb-3 text-theme font-bold headingFont">Join HKITA</h2>
								<p className=" text-center">
									If you are interested in our US College Program,{' '}
									<a href="https://forms.gle/E2h1B9y92JJE57W28" target="_blank" rel="noreferrer" className=" text-inherit font-normal pt-4">
										tell us more about you.
									</a>
								</p>
							</div>
							<Row className="justify-content-center">
								<Col className="col-lg-6 col-12 mb-md-3 inputHolder mb-2">
									<Label aria-required>First Name</Label>
									<CustomInput
										validate={formik.touched.first_name_en && formik.errors.first_name_en}
										type="text"
										className="form-control"
										placeholder="First Name"
										name="first_name_en"
										value={formik.values.first_name_en}
										onChange={(event) => addToFormik('first_name_en', event.target.value)}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.first_name_en && formik.errors.first_name_en ? (
										<div className="error">{formik.errors.first_name_en}</div>
									) : (
										''
									)}
								</Col>

								<Col className="col-lg-6 col-12 mb-md-3 inputHolder mb-2">
									<Label aria-required>Last Name</Label>
									<CustomInput
										validate={formik.touched.last_name_en && formik.errors.last_name_en}
										type="text"
										className="form-control"
										placeholder="Last Name"
										name="last_name_en"
										value={formik.values.last_name_en}
										onChange={(event) => formik.setFieldValue('last_name_en', event.target.value.toUpperCase())}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.last_name_en && formik.errors.last_name_en ? <div className="error">{formik.errors.last_name_en}</div> : ''}
								</Col>

								<Col className="col-lg-6 col-12 mb-md-3 inputHolder mb-2">
									<Label aria-required>Date of Birth</Label>
									<CustomDatePicker
										validate={formik.touched.dob && formik.errors.dob}
										name="dob"
										className="form-control"
										value={formik.values.dob}
										onChange={(value) => formik.setFieldValue('dob', value)}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.dob && formik.errors.dob ? <div className="error">{formik.errors.dob}</div> : ''}
								</Col>

								<Col className="col-lg-6 col-12 inputHolder">
									<Label aria-required>Select Gender</Label>
									<LtSelect
										validate={formik.touched.gender && formik.errors.gender}
										value={formik.values.gender}
										onChange={(value) => formik.setFieldValue('gender', value.value)}
										options={[
											{ label: 'Select Gender', value: '' },
											{ value: 'M', label: 'Male' },
											{ value: 'F', label: 'Female' },
										]}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.gender && formik.errors.gender ? <div className="error">{formik.errors.gender}</div> : ''}
								</Col>

								{/* <Col className="col-lg-6 col-12 inputHolder">
                  <Label aria-required>Select Center</Label>
                  <LtSelect
                    isSearch={true}
                    validate={
                      formik.touched.center_id && formik.errors.center_id
                    }
                    value={formik.values.center_id}
                    onChange={(value) =>
                      formik.setFieldValue("center_id", value.value)
                    }
                    options={centers}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.center_id && formik.errors.center_id ? (
                    <div className="error">{formik.errors.center_id}</div>
                  ) : (
                    ""
                  )}
                </Col> */}

								<Col className="col-lg-6 col-12 inputHolder">
									<Label aria-required>Select Program</Label>
									<LtSelect
										validate={formik.touched.program_id && formik.errors.program_id}
										value={formik.values.program_id}
										onChange={(value) => formik.setFieldValue('program_id', value.value)}
										options={programList}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.program_id && formik.errors.program_id ? <div className="error">{formik.errors.program_id}</div> : ''}
								</Col>

								<Col className="col-lg-6 col-12 mb-md-3 inputHolder mb-2">
									<Label aria-required>Email Address</Label>
									<CustomInput
										validate={formik.touched.email && formik.errors.email}
										type="email"
										className="form-control"
										placeholder="Email Address"
										name="email"
										value={formik.values.email}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.email && formik.errors.email ? <div className="error">{formik.errors.email}</div> : ''}
								</Col>

								<Col className="col-lg-6 col-12 mb-md-3 inputHolder mb-2">
									<Label aria-required>Mobile</Label>
									<PhoneInput
										validate={formik.touched.mobile && formik.errors.mobile}
										countryCodeEditable={false}
										country={countryCode.toLowerCase()}
										value={formik.values.mobile}
										onChange={(phone, country, e, formattedValue) => {
											formik.setFieldValue('mobile', formattedValue);
										}}
										onBlur={formik.handleBlur}
										className="w-full"
									/>

									{formik.touched.mobile && formik.errors.mobile ? <div className="error">{formik.errors.mobile}</div> : ''}
								</Col>
								<Col className="col-lg-6 col-12 mb-md-3 inputHolder mb-2">
									<Label aria-required>Address</Label>
									<CustomInput
										validate={formik.touched.address && formik.errors.address}
										type="text"
										className="form-control"
										placeholder="Address"
										name="address"
										value={formik.values.address}
										onChange={(event) => formik.setFieldValue('address', event.target.value)}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.address && formik.errors.address ? <div className="error">{formik.errors.address}</div> : ''}
								</Col>

								<Col className="col-lg-6 col-12 mb-md-3 inputHolder mb-2">
									<Label aria-required>Parent First Name</Label>
									<CustomInput
										validate={formik.touched.parent_first_name && formik.errors.parent_first_name}
										type="text"
										className="form-control"
										placeholder="Parent Name"
										name="parent_first_name"
										value={formik.values.parent_first_name}
										onChange={(event) => formik.setFieldValue('parent_first_name', event.target.value)}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.parent_first_name && formik.errors.parent_first_name ? (
										<div className="error">{formik.errors.parent_first_name}</div>
									) : (
										''
									)}
								</Col>

								<Col className="col-lg-6 col-12 mb-md-3 inputHolder mb-2">
									<Label aria-required>Parent Last Name</Label>
									<CustomInput
										validate={formik.touched.parent_last_name && formik.errors.parent_last_name}
										type="text"
										className="form-control"
										placeholder="Parent Name"
										name="parent_last_name"
										value={formik.values.parent_last_name}
										onChange={(event) => formik.setFieldValue('parent_last_name', event.target.value.toUpperCase())}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.parent_last_name && formik.errors.parent_last_name ? (
										<div className="error">{formik.errors.parent_last_name}</div>
									) : (
										''
									)}
								</Col>

								<Col className="col-lg-6 col-12 mb-md-3 inputHolder mb-2">
									<Label aria-required>Parent Email</Label>
									<CustomInput
										validate={formik.touched.parent_email && formik.errors.parent_email}
										type="text"
										className="form-control"
										placeholder="Parent Email"
										name="parent_email"
										value={formik.values.parent_email}
										onChange={(event) => formik.setFieldValue('parent_email', event.target.value)}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.parent_email && formik.errors.parent_email ? <div className="error">{formik.errors.parent_email}</div> : ''}
								</Col>

								<Col className="col-lg-6 col-12 mb-md-3 inputHolder mb-2">
									<Label aria-required>Parent Mobile</Label>
									<PhoneInput
										validate={formik.touched.parent_mobile && formik.errors.parent_mobile}
										countryCodeEditable={false}
										country={countryCode.toLowerCase()}
										value={formik.values.parent_mobile}
										onChange={(phone, country, e, formattedValue) => {
											formik.setFieldValue('parent_mobile', formattedValue);
										}}
										onBlur={formik.handleBlur}
										className="w-full"
									/>

									{formik.touched.parent_mobile && formik.errors.parent_mobile ? (
										<div className="error">{formik.errors.parent_mobile}</div>
									) : (
										''
									)}
								</Col>

								<Col className="col-lg-6 col-12 inputHolder">
									<Label aria-required>Select Relation Type</Label>
									<LtSelect
										validate={formik.touched.relation_type && formik.errors.relation_type}
										value={formik.values.relation_type}
										onChange={(value) => formik.setFieldValue('relation_type', value.value)}
										options={[
											{ label: 'Select Relation', value: '' },
											{ value: 0, label: 'Guardian' },
											{ value: 1, label: 'Father' },
											{ value: 2, label: 'Mother' },
										]}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.relation_type && formik.errors.relation_type ? (
										<div className="error">{formik.errors.relation_type}</div>
									) : (
										''
									)}
								</Col>

								<Col className="col-lg-6 col-12 mb-md-3 position-relative inputHolder mb-2">
									<Label aria-required>New Password</Label>
									<span className="eyeicon" onClick={() => (showPwd !== true ? setShowPwd(true) : setShowPwd(false))}>
										{showPwd === false ? <HiOutlineEyeOff /> : <HiOutlineEye />}
									</span>
									<CustomInput
										validate={formik.touched.password && formik.errors.password}
										type={showPwd ? 'text' : 'password'}
										className="form-control"
										placeholder="Password"
										name="password"
										value={formik.values.password}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.password && formik.errors.password ? <div className="error">{formik.errors.password}</div> : ''}
								</Col>

								<Col className="col-lg-6 col-12 mb-md-3 position-relative inputHolder mb-2">
									<Label aria-required>Confirm Password</Label>
									<span className="eyeicon" onClick={() => (showConfPwd !== true ? setShowConfPwd(true) : setShowConfPwd(false))}>
										{showConfPwd === false ? <HiOutlineEyeOff /> : <HiOutlineEye />}
									</span>

									<CustomInput
										validate={formik.touched.confirmPassword && formik.errors.confirmPassword}
										type={showConfPwd ? 'text' : 'password'}
										className="form-control"
										placeholder="Password"
										name="confirmPassword"
										value={formik.values.confirmPassword}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.confirmPassword && formik.errors.confirmPassword ? (
										<div className="error">{formik.errors.confirmPassword}</div>
									) : (
										''
									)}
								</Col>

								<Col className="col-lg-6 col-12 mb-md-3 inputHolder mb-2">
									<Label aria-required>Select Country</Label>
									<LtSelect
										isSearch={true}
										validate={formik.touched.country_id && formik.errors.country_id}
										value={formik.values.country_id}
										onBlur={formik.handleBlur} // pass onBlur handler from Formik form
										form={formik} // pass the formik instance to CustomSelect
										onChange={(value) => formik.setFieldValue('country_id', value.value)}
										options={country}
									/>
									{formik.touched.country_id && formik.errors.country_id ? <div className="error">{formik.errors.country_id}</div> : null}
								</Col>
								<Col className="col-lg-6 col-12 mb-md-3 inputHolder mb-2">
									<Label aria-required>Select State</Label>
									<LtSelect
										isSearch={true}
										validate={formik.touched.state_id && formik.errors.state_id}
										value={formik.values.state_id}
										onBlur={formik.handleBlur} // pass onBlur handler from Formik form
										form={formik} // pass the formik instance to CustomSelect
										onChange={(value) => formik.setFieldValue('state_id', value.value)}
										options={state}
									/>
									{formik.touched.state_id && formik.errors.state_id ? <div className="error">{formik.errors.state_id}</div> : null}
								</Col>
								<Col className="col-lg-6 col-12 mb-md-3 inputHolder mb-2">
									<Label>Select City</Label>
									<LtSelect
										isSearch={true}
										validate={formik.touched.city_id && formik.errors.city_id}
										value={formik.values.city_id}
										onBlur={formik.handleBlur} // pass onBlur handler from Formik form
										form={formik} // pass the formik instance to CustomSelect
										onChange={(value) => formik.setFieldValue('city_id', value.value)}
										options={cities}
									/>
									{/* {formik.touched.city_id && formik.errors.city_id ? (
                  <div className="error">{formik.errors.city_id}</div>
                ) : null} */}
								</Col>

								<Col className="col-lg-12 col-12 mb-md-3 inputHolder mb-2 text-end">
									<ReCAPTCHA ref={recaptchaRef} size="normal" sitekey={sitekey} onChange={onChange} />
									{recaptchaError ? <div className="error recaptchaError text-start">Recaptcha is Required</div> : ''}
								</Col>
								<Col className="col-lg-6 col-12 mb-md-3 inputHolder mb-2 text-end">
									<Button type="submit" className="px-md-5 w-100" variant="primary" onClick={submitForm}>
										{isLoading ? (
											<div className="loader_sm">
												<HiOutlineRefresh />
											</div>
										) : (
											'Submit'
										)}
									</Button>
									{/* <p className="mt-2 text-center">
										Already have an Account? <a href="/login">Login</a>
									</p> */}
								</Col>
							</Row>
						</Form>
					</div>
				</div>
			</Container>
		</section>
	);
}
