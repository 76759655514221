import React from 'react';
import { Container, Nav } from 'react-bootstrap';

const OurStorySection = () => {
	return (
		<section className="text-center md:py-[70px] py-[100px] bg-slate-100 relative">
			<Container>
				<div className="max-w-screen-md mx-auto">
					<h6 className="text-theme">OUR STORY</h6>
					<h2 className="mb-3 font-bold headingFont">Hong Kong International Tennis Academy</h2>
					<p className="">
						Founded to foster local talent and place Hong Kong on the global tennis map, the academy's vision is clear: to nurture athletes capable
						of competing at the highest levels while emphasizing education, discipline, and personal growth.
					</p>
					<Nav.Link className="text-main uppercase hover:underline hover:underline-offset-1" href="/our-story">
						Learn More Here
					</Nav.Link>
				</div>
			</Container>
		</section>
	);
};

export default OurStorySection;
