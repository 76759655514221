import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';
import { players as PlayerList } from '../data/players';

const Players = () => {
	const sliderRef = useRef(null);

	const settings = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<section className="testimonial_bg md:py-[70px] py-[100px] bg-slate-100 relative">
			<Container>
				<div className="row align-items-center">
					<div className="col-md-5 heading">
						<h6 className="text-theme">Highlight</h6>
						<h2 className="mb-4 text-dark font-bold headingFont">Our Players</h2>
						<p className="text-dark text-justify">
							HKITA is proud of the accomplishments of our students on - and off - the court. We will endeavor to keep you up to date on their
							successes.
						</p>
						<div className="mt-4">
							<button
								onClick={() => sliderRef.current.slickPrev()}
								className="inline-block h-[50px] w-[50px] bg-white hover:scale-110 border-none transition-all hover:bg-slate-800 hover:text-white rounded-full m-2 pb-1">
								<MdOutlineArrowBackIosNew />
							</button>
							<button
								onClick={() => sliderRef.current.slickNext()}
								className="inline-block h-[50px] w-[50px] bg-white hover:scale-110 border-none transition-all hover:bg-slate-800 hover:text-white rounded-full m-2 pb-1">
								<MdOutlineArrowForwardIos />
							</button>
						</div>
					</div>
					<div className="col-md-7">
						<Slider ref={sliderRef} {...settings}>
							{PlayerList.map(
								(player, index) =>
									player.achievements.length > 0 && (
										<div key={index} className="px-2 md:pb-5 pb-3 pt-[100px] testimonial_card h-full">
											<div className="bg-white rounded-lg shadow-md p-6 text-center">
												<img
													src={player?.avatar}
													alt={player.name}
													className="rounded-full w-[100px] h-[100px] mt-[-80px] mx-auto mb-3 object-cover"
												/>
												<h3 className="text-xl font-semibold">{player.name}</h3>
												{player.achievements.length > 0 ? player.achievements.map((i, index) => <>{i}, </>) : '-'}
												<p className="text-gray-500 mb-2"> </p>
											</div>
										</div>
									)
							)}
						</Slider>
					</div>
				</div>
			</Container>
		</section>
	);
};

export default Players;
