import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { IoCheckmarkDoneCircle } from 'react-icons/io5';

export default function MonsterTennis() {
	return (
		<>
			<section
				className=" pt-[70px] mb-[50px] pb-[200px] bg-slate-800 relative"
				style={{
					backgroundPosition: 'bottom',
					backgroundImage: 'url(./assets/images/monster_tennis_bg.jpg)',
				}}>
				<Container>
					<Row className="items-center pt-[70px]">
						<Col md={12} className="heading pr-5 md:pr-3">
							<h6 className="text-white">Join the Excitement of Monster Tennis!</h6>
							<h2 className="mb-3 text-white font-bold headingFont">Monster Tennis Program for Ages 3 and Above</h2>
							<p className="text-white">
								Monster Tennis provides a fun and engaging tennis experience designed specifically for kids aged 3 and above, using specialized
								equipment, appropriate court size, and a game-based approach to ensure the right development for every age group.
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<Container className="mb-4 p-5 md:p-3 mt-[-70px] bg-white relative z-10">
				<img src="/assets/images/monster_tennis/monster-tennis-logo.png" alt="Monster Tennis Logo" className="w-full md:w-96" />

				<h1 className="mt-3 mb-2 text-theme font-bold headingFont">Monster Tennis Classes</h1>
				<h4 className="my-4">Our Monster Tennis program offers four skill-specific levels:</h4>
				<ul className="list-disc ml-5 my-4">
					<li className="h5 mb-4">
						<IoCheckmarkDoneCircle className="text-theme" />
						&nbsp;
						<strong className="text-theme font-black capitalize">Red Ball Players</strong>
					</li>
					<li className="h5 mb-4">
						<IoCheckmarkDoneCircle className="text-theme" />
						&nbsp;
						<strong className="text-theme font-black capitalize">Orange Ball Players</strong>
					</li>
					<li className="h5 mb-4">
						<IoCheckmarkDoneCircle className="text-theme" />
						&nbsp;
						<strong className="text-theme font-black capitalize">Green Ball Players </strong>
					</li>
					<li className="h5 mb-4">
						<IoCheckmarkDoneCircle className="text-theme" />
						&nbsp;
						<strong className="text-theme font-black capitalize">Yellow Ball Players</strong>
					</li>
				</ul>
				<h4 className="mt-4">
					Each program utilizes different size rackets, different bounce tennis balls, and even different court dimensions to ensure children progress
					according to their age and ability.
				</h4>
			</Container>
			<Container className="relative mb-4 z-10 p-0">
				<Row>
					<Col md={6}>
						<Container className="mb-5 p-5 h-100 md:p-3 bg-white relative z-10 rounded-lg shadow-lg">
							<h5 className="mt-3 mb-2 text-theme font-bold headingFont">The Rules of Tennis Have Changed!</h5>
							<p>
								Out with the fast yellow ball and in with the slower Red, Orange, and Green balls! Since January 2012, the new rule for
								10-and-Under tennis competition has been in effect. Slower balls, smaller courts, easier games!
							</p>
						</Container>
					</Col>
					<Col md={6}>
						<Container className="mb-5 p-5 h-100 md:p-3 bg-white relative z-10 rounded-lg shadow-lg">
							<h5 className="mt-3 mb-2 text-theme font-bold headingFont">Monster Tennis Birthday Party</h5>
							<p>
								Thinking of something fun for your child's birthday? How about an hour of Monster Tennis fun as part of their birthday party? A
								coach will come to your club and host exciting tennis games for the kids. Contact Benny Lin at{' '}
								<a href="mailto:benny.lin@hkita.com" className="text-theme normal-case">
									benny.lin@hkita.com
								</a>{' '}
								for inquiries.
							</p>
						</Container>
					</Col>
				</Row>
			</Container>

			<Container className="mb-4 p-5 md:p-3 bg-white relative z-10 rounded-lg shadow-lg">
				<Col md={7}>
					<h5 className="mt-3 mb-2 text-theme font-bold headingFont">Policy</h5>
					<p>
						New students will be assessed during a trial lesson. Our coach will evaluate the student's skill level and recommend the appropriate
						class.
					</p>
					<ul className="list-disc ml-5">
						<li>
							<IoCheckmarkDoneCircle className="text-theme" />
							&nbsp;
							<strong>No Show Policy:</strong> 6 hours' notice must be given to the coach for a missed class. Makeup classes are available and
							must be taken within 4 weeks of cancellation.
						</li>
						<li>
							<IoCheckmarkDoneCircle className="text-theme" />
							&nbsp;
							<strong>Rain Cancellation Policy:</strong> Classes are canceled in the event of rain. Parents will be notified at least 30 minutes
							before the lesson. Canceled classes will be credited towards the following term or a make-up lesson can be arranged during the same
							term.
						</li>
						<li>
							<IoCheckmarkDoneCircle className="text-theme" />
							&nbsp;
							<strong>Public Holiday:</strong> No classes on public holidays.
						</li>
						<li>
							<IoCheckmarkDoneCircle className="text-theme" />
							&nbsp;
							<strong>Refund Policy:</strong> No refund is available once the session has begun.
						</li>
					</ul>
				</Col>
			</Container>

			<Container className="mb-4 p-5 md:p-3 bg-white relative z-10 rounded-lg shadow-lg">
				<Col md={7}>
					<h5 className="mt-3 mb-2 text-theme font-bold headingFont">About Monster Tennis</h5>
					<p>
						Monster Tennis® is a physical play program for children ages 3 and above, aimed at introducing them to tennis. The program uses
						specialized equipment, shorter court dimensions, and game-based methods to teach children:
					</p>
					<ul className="list-disc ml-5">
						<li>
							<IoCheckmarkDoneCircle className="text-theme" />
							&nbsp; Hand-eye coordination
						</li>
						<li>
							<IoCheckmarkDoneCircle className="text-theme" />
							&nbsp; Ball tracking skills
						</li>
						<li>
							<IoCheckmarkDoneCircle className="text-theme" />
							&nbsp; Basic movements and stroke fundamentals
						</li>
						<li>
							<IoCheckmarkDoneCircle className="text-theme" />
							&nbsp; Improved concentration, focus, and most importantly, LOTS OF FUN!
						</li>
					</ul>
					<p>
						Benefits go beyond just physical activity, aiding in the development of cognitive, social, and emotional skills while enhancing muscle
						and bone growth.
					</p>
				</Col>
			</Container>
			<Container
				style={{
					backgroundSize: 'cover',
					backgroundImage: 'url(./assets/images/MonsterTennisClassesBg.jpg)',
				}}
				className="mb-4 p-5 md:p-3 bg-white relative z-10 rounded-lg shadow-lg">
				<Col md={7}>
					<h5 className="mt-3 mb-2 text-theme font-bold headingFont">Location & Schedule</h5>
					<p>
						Our classes are taught at the following venues. Please email{' '}
						<a href="mailto:benny.lin@hkita.com" className="text-theme normal-case">
							benny.lin@hkita.com
						</a>{' '}
						to inquire about class schedules:
					</p>
					<ul className="list-disc ml-5 ">
						<li>
							<IoCheckmarkDoneCircle className="text-theme" />
							&nbsp; Kowloon: King's Park Tennis Courts, Ho Man Tin Sports Center, Morse Park No.4
						</li>
						<li>
							<IoCheckmarkDoneCircle className="text-theme" />
							&nbsp; New Territories: Tsang Tai Uk Recreation Ground, Sai Kung Tennis Courts, Ma On Shan Recreation Sports Ground, Tiu Keng Leng
							Sports Centre, Po Tsui Park Tennis Courts
						</li>
						<li>
							<IoCheckmarkDoneCircle className="text-theme" />
							&nbsp; Hong Kong Island: Hong Kong Tennis Centre, Causeway Bay Recreation Ground
						</li>
					</ul>
				</Col>
			</Container>
		</>
	);
}
