import React, { useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';
import LtModal from '../component/LtModal';
import { players } from '../data/players';

const Testimonials = () => {
	const sliderRef = useRef(null);
	const [show, setShow] = useState(false); // State for modal visibility
	const [selected, setSelected] = useState(null); // State for selected id

	const handleClose = () => setShow(false);
	const handleShow = (id) => {
		setSelected(id);
		setShow(true);
	};

	const settings = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<>
			<section className="testimonial_bg md:py-[70px] py-[100px] bg-slate-800 bg-opacity-55 relative" data-aos="fade-up">
				<Container>
					<div className="row align-items-center">
						<div className="col-md-5 heading">
							<h6 className="text-theme text-white">Testimonials</h6>
							<h2 className="mb-3 text-white font-bold headingFont">What Our Players Say </h2>
							<p className="text-white">Here are some of players' testimonials.</p>
							<p className="text-white">
								Our program is not just about hitting tennis balls. The program is heavily emphasized on providing a pathway for our young
								players to reach their full potentials, such as turning professional or receiving US College scholarships.
							</p>
							<p className="text-white">
								Our extensive worldwide connections will enable us to assist our players in their overall development and allow them to study at
								some of the top universities around the world.
							</p>
							<div className="mt-4">
								<button
									onClick={() => sliderRef.current.slickPrev()}
									className="inline-block h-[50px] w-[50px] bg-white hover:scale-110 border-none transition-all hover:bg-slate-800 hover:text-white rounded-full m-2 pb-1">
									<MdOutlineArrowBackIosNew />
								</button>
								<button
									onClick={() => sliderRef.current.slickNext()}
									className="inline-block h-[50px] w-[50px] bg-white hover:scale-110 border-none transition-all hover:bg-slate-800 hover:text-white rounded-full m-2 pb-1">
									<MdOutlineArrowForwardIos />
								</button>
							</div>
						</div>
						<div className="col-md-7">
							<Slider ref={sliderRef} {...settings}>
								{players.map((testimonial, index) => (
									<div key={index} className="px-2 md:pb-5 pb-3 pt-[100px] testimonial_card">
										<div className="bg-white rounded-lg shadow-md p-6 text-center">
											{testimonial.avatar && (
												<img
													src={testimonial.avatar}
													alt={testimonial.name}
													className="object-cover rounded-full w-[100px] h-[100px] mt-[-80px] mx-auto mb-3"
												/>
											)}
											<h3 className="text-xl font-semibold">{testimonial.name}</h3>
											<p key={index} className="text-gray-500 mb-2 overflow-hidden line-clamp-1">
												{testimonial.achievements.length > 0 ? testimonial.achievements.map((i, index) => <>{i}, </>) : '-'}
											</p>

											<p
												className="text-gray-600 text-justify overflow-hidden line-clamp-6"
												dangerouslySetInnerHTML={{ __html: testimonial.feedback }}
											/>
											<button
												className="border-none bg-slate-200 rounded-md py-2 px-4 hover:bg-slate-300"
												onClick={() => handleShow(testimonial)}>
												Read More
											</button>
										</div>
									</div>
								))}
							</Slider>
						</div>
					</div>
				</Container>
			</section>
			<LtModal isOpen={show} onClose={handleClose} heading="" width={500}>
				<div className="text-center mx-auto">
					{selected?.avatar && (
						<img src={selected?.avatar} alt={selected?.name} className="object-cover rounded-full w-[100px] h-[100px] mx-auto mb-3" />
					)}
				</div>
				<h4 className="text-xl font-semibold text-center">
					{selected?.name}, {selected?.country}
				</h4>
				<p className="text-gray-500 mb-2 text-center">
					{selected?.achievements.length > 0 ? selected?.achievements.map((i, index) => <>{i}, </>) : '-'}
				</p>

				<p className="text-justify" dangerouslySetInnerHTML={{ __html: selected?.feedback }} />
			</LtModal>
		</>
	);
};

export default Testimonials;
