import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="mx-auto flex justify-center items-center h-lvh flex-col">
      <div class="notfound">
        <div class="notfound-404">
          <h1 className="mb-4">404</h1>
          <h5 className="font-bold headingFont">
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable.
          </h5>
          <Link to="/" className="text-white w-[200px]  uppercase mt-4 relative">
            Go Back to Home
          </Link>
        </div>
      </div>

      <style jsx="true">
        {`
          .notfound {
            max-width: 710px;
            width: 100%;
            text-align: center;
          }

          .notfound .notfound-404 {
            position: relative;
          }

          @keyframes zoomAnimation {
            0% {
              background-size: 100%;
            }
            50% {
              background-size: 120%;
            }
            100% {
              background-size: 100%;
            }
          }
          
          .notfound .notfound-404 h1 {
            font-family: "Montserrat", sans-serif;
            font-size: 230px;
            font-weight: 900;
            width: 710px;
            margin: 0 auto;
            background: url("https://colorlib.com/etc/404/colorlib-error-404-10/img/bg.jpg") no-repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-position: center;
            animation: zoomAnimation 10s infinite ease-in-out; /* Added animation */
          }
          

          .notfound h2 {
            font-family: "Montserrat", sans-serif;
            color: #000;
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            margin-top: 0;
          }

          .notfound p {
            font-family: "Montserrat", sans-serif;
            color: #000;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 20px;
            margin-top: 0px;
          }

          .notfound a {
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            text-decoration: none;
            text-transform: uppercase;
            background: #0046d5;
            display: inline-block;
            padding: 15px 30px;
            border-radius: 40px;
            color: #fff;
            font-weight: 700;
            -webkit-box-shadow: 0px 4px 15px -5px #0046d5;
            box-shadow: 0px 4px 15px -5px #0046d5;
          }

          @media only screen and (max-width: 767px) {
				.notfound {
					max-width: calc(100% - 50px);
					text-align: center;
					padding: 70px 0 0;
			  }
            .notfound .notfound-404 h1 {
              font-size: 72px;
				  width:auto;
            }
          }
        `}
      </style>
    </div>
  );
};

export default PageNotFound;
