// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './includes/Header';
import Footer from './includes/Footer';
import Home from './pages/Home.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import OurStory from './pages/OurStory.js';
import CourtOfOpportunities from './pages/CourtOfOpportunities.js';
import PageNotFound from './pages/PageNotFound.js';
import ContactUs from './pages/ContactUs.js';
import MonsterTennis from './pages/MonsterTennis/MonsterTennis.js';
import SocialBar from './component/SocialBar';
import Registration from './pages/Auth/Registration.js';
// import Login from './pages/Auth/Login.js';

function App() {
	return (
		<Router>
			<div className="flex flex-col min-h-screen">
				<Header />
				<main className="flex-grow">
					<Routes>
						<Route path="/" element={<Home isHome />} />
						<Route path="/our-story" element={<OurStory />} />
						<Route path="/court-of-opportunities" element={<CourtOfOpportunities />} />
						<Route path="/monster-tennis" element={<MonsterTennis />} />
						<Route path="/contact-us" element={<ContactUs />} />
						{/* <Route path="/login" element={<Login />} /> */}
						<Route path="/register" element={<Registration />} />
						<Route path="*" element={<PageNotFound />} />
					</Routes>
				</main>
				<Footer />
				<SocialBar variant={'variant1'} />
			</div>
		</Router>
	);
}

export default App;
